<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Availability from "../../../components/availability/availability"
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"

import {
	availabilityMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
	page: {
		title: "Availability",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		Availability,
		EmptyList
	},
	data() {
		return {
			title: "",
			items: [],
			showModal: false,
			isLoading: false,
			fullPage: true,
			canCancel: false,
			useSlot: false,
			color: "#007bff",
			bgColor: "#ffffff",
			height: 128,
			width: 128,   
      
      submitted:false,
      currentPage:1,
			perPage:8,
			rows:0,
      availabilities:[],
			availabilityId : null,
			confirmDelete:false,
			availability: {
				name:null
			},
			empty_list_config:{
					"title" : this.$t("availability.empty_search_title"),
					"linkButtonText" : this.$t("common.reset_search"),
					icon: "fa-cubes"
			},
			empty_config:{
					"title" : this.$t("availability.empty_title"),
					"subtitle" : this.$t("availability.empty_text"),
					"buttonText" : this.$t('availability.new'),
					icon: "fa-cubes"
			},
			query:''
		};
	},
	mounted() {
		this.loadAvailabilites();
	},
	created(){
		this.debounceAvailabilities = _debounce(this.searchAvailabilities.bind(this), 1000);
	},
	methods: {
		...availabilityMethods,
		onNewAvailabilityClicked(){
			this.submitted = false
			this.availability = {
				name : null
			},
			this.showModal = true;
		},
    loadAvailabilites(){
      
			let loader = this.$loading.show();
			this.isLoading = true;
			const params={
				q: `limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
			}

			this.getAvailabilities(params).then((availabilities)=>{
				if(availabilities&& availabilities.data){
					this.submitted= false;
					this.availabilities= availabilities.data;
					this.rows= availabilities.totalCount
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("availability.get_availability_error"),title:  this.$t("availability.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
      
    },
    onAvailabilityListPageClicked(){
      this.loadAvailabilites();
    },
		onCancelNewAvailabilityClicked(){
			this.showModal = false;
			this.availability = {
				name : null
			}
		},

		onEditAvailability(){
			let loader = this.$loading.show();
				this.updateAvailability(this.availability).then(()=>{
					this.$notify({ type: 'success', text: this.$t("availability.update_availability_success"),title:  this.$t("availability.title") });
					this.showModal= false;
					this.currentPage=1;
					this.loadAvailabilites();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("availability.update_availability_error"),title:  this.$t("availability.title") });		
				}).finally(()=>{
					loader.hide();
				})
		},
		onConfirmNewAvailabilityClicked(){
			
			let isOk = this.$refs.availabilityComponent.onCreateAvailability();

			if(!isOk)
				return;
			
			if(this.availability._id){
				this.onEditAvailability();
			}
			else{
				let loader = this.$loading.show();
				this.createAvailability(this.availability).then(()=>{
					this.$notify({ type: 'success', text: this.$t("availability.create_availability_success"),title:  this.$t("availability.title") });
					this.showModal= false;
					this.currentPage=1;
					this.loadAvailabilites();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("availability.create_availability_error"),title:  this.$t("availability.title") });		
				}).finally(()=>{
					loader.hide();
				})
			}
			

		},
		onConfirmRemoveAvailabilityClicked(){
			let loader = this.$loading.show();
				this.deleteAvailability(this.availabilityId).then(()=>{
						this.$notify({ type: 'success', text: this.$t("availability.delete_availability_success"),title:  this.$t("availability.title") });
						this.currentPage=1;
						this.loadAvailabilites();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("availability.delete_availability_error"),title:  this.$t("availability.title") });		
				}).finally(()=>{
						loader.hide();
						this.availabilityId=null;
				})
		},
		onEditAvailabilityClicked(availability){
			this.showModal = true;
			this.availability = availability; 
		},
		onDeleteAvailabilityClicked(availabilityId){
				this.confirmDelete = true;
				this.availabilityId = availabilityId
		},
		searchAvailabilities(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
			this.availabilities=[];
			this.empty_list_config.subtitle =  this.$t("availability.empty_search_text").replaceAll('#', this.query);
			if(query){
				this.currentPage = 1;
						const params={
							q: `where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getAvailabilities(params).then((availabilities)=>{
							if(availabilities&& availabilities.data){
									this.availabilities= availabilities.data;
									this.rows= availabilities.totalCount
							}
				}).catch(()=>{
							this.$notify({ type: 'error', text: this.$t("availabilities.get_availability_error"),title:  this.$t("availability.title") });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
				this.currentPage = 1;
				loader.hide();
				this.loadUsers();
			}
		},
		onResetSearchClicked(){
			this.query = '';
      this.loadAvailabilites();
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('availability.title')" :items="items" />
			<div class="row">
					<div class="col-12">
						<div class="card">
						<div class="card-body">
								<div class="row ">
								<div class="col-sm-4">
									<div class="search-box me-2 mb-0 d-inline-block">
										<div class="position-relative">
												<input
												type="text"
												class="form-control"
												v-model="query"
												:placeholder="$t('common.search')"
												@input="debounceAvailabilities($event.target.value)"
												/>
												<i class="bx bx-search-alt search-icon"></i>
										</div>
										</div>
								</div>
								<div class="col-sm-8">
										<div class="text-sm-end">
												<button
														type="button"
														class="btn btn-primary mb-0 me-0"
														@click="onNewAvailabilityClicked"
														v-if="availabilities.length>0 || query!=''">
														<i class="mdi mdi-plus me-1"></i> {{$t('availability.new')}}
												</button>
												
												<b-modal
														v-model="showModal"
														:title="availability._id ? availability.name : $t('availability.new')"
														title-class="text-black font-18"
														size="lg"
														body-class="p-3"
														hide-footer>
														<Availability :availability="availability" ref="availabilityComponent"/>
														<div class="text-end pt-5 mt-3">
																<b-button variant="light" @click="onCancelNewAvailabilityClicked">{{$t('common.cancel')}}</b-button>
																<b-button variant="primary" class="ms-1" @click="onConfirmNewAvailabilityClicked">{{$t('common.confirm')}}</b-button>
														</div>
												</b-modal>
											</div>
								</div>
								<!-- end col-->
								</div>
            </div>
						<EmptyList :config="empty_list_config" v-if="!isLoading && availabilities.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
						<EmptyList :config="empty_config" v-if="!isLoading && availabilities.length == 0 && query ==''" @onButtonClicked="onNewAvailabilityClicked" class="mt-3 mb-3"/>

						<div class="table-responsive" v-if="!isLoading && availabilities.length > 0">
								<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
										<thead class="table-light">
										<tr>
												<th scope="col" style="width: 80%;">{{$t('availability.name')}}</th>
												<th scope="col"></th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="availability in availabilities" :key="availability._id">
												<td @click="onEditAvailabilityClicked(availability)">
                          <h5 class="font-size-14 mb-1">
                              <a href="#" class="text-dark">{{availability.name}}</a>
                          </h5>
                        </td>
												
												<td class="text-end">
													<i class="fas fa-edit text-success me-2 interact" v-on:click="onEditAvailabilityClicked(availability)"></i>
													<i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onDeleteAvailabilityClicked(availability._id)"></i>
												</td>
											</tr>
										</tbody>
								</table>
								<b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveAvailabilityClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
										<p>{{$t('availability.remove')}}</p>
								</b-modal>
								</div>
						</div>
						<div class="row mb-4" v-if="!isLoading && rows > perPage">
							<div class="col">
									<div class="dataTables_paginate paging_simple_numbers float-end">
											<ul class="pagination pagination-rounded mb-0">
													<b-pagination
															@input="onAvailabilityListPageClicked"
															v-model="currentPage"
															:total-rows="rows"
															:per-page="perPage"
													></b-pagination>
											</ul>
									</div>
							</div>
						</div>
					</div>
				</div>
	</Layout>
</template>
